import { render, staticRenderFns } from "./ArticleImgSelector.component.vue?vue&type=template&id=641676dc&scoped=true"
import script from "./ArticleImgSelector.component.vue?vue&type=script&lang=ts"
export * from "./ArticleImgSelector.component.vue?vue&type=script&lang=ts"
import style0 from "./ArticleImgSelector.component.vue?vue&type=style&index=0&id=641676dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641676dc",
  null
  
)

export default component.exports